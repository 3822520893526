<template>
  <div class="w-100 h-100">
    <e-charts ref="chart" autoresize :option="optionData" :theme="theme" auto-resize v-if="optionData" />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import 'echarts/lib/component/polar';
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import theme from "./theme.json";
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import store from "@/store";

export default {
  props: {
    datasourceId: {
      type: String,
      default: null
    }
  },
  components: {
    ECharts
  },
  data() {
    return {
      theme,
      optionData: {},
      loading: false
    };
  },
  mounted() {
    this.reload()
  },
  methods: {
    async reload() {
      this.loading = true;
      this.optionData = await fetcher(apis.dynamicChart + "/" + this.datasourceId, FetchMethod.GET);
      this.loading = false;
    },
    resize() {
      this.$refs["chart"][0].resize();
    }
  }
};
</script>

<style></style>
