<template>
  <div v-loading="loading">
    <b-button variant="success" @click="addItem" :disabled="locked" class="mx-1">Add</b-button>
    <b-button variant="danger" @click="locked = !locked">{{ locked ? "Unlock" : "Lock" }}</b-button>
    <dashboard :id="'dashboard'" @currentBreakpointUpdated="breakpointUpdated">
      <dash-layout v-for="layout in dlayouts" v-bind="layout" :debug="false" :key="layout.breakpoint">
        <dash-item v-for="item in layout.items" v-bind.sync="item" :key="item.id" @moveEnd="itemMoved" @resizeEnd="itemResized" :locked="locked">
          <div style="height: 100%; width: 100%; border: 2px solid #eee; border-radius: 5px" class="p-0 d-flex flex-column bg-white">
            <div style="height: 50px">
              <div style="float: right">
                <b-dropdown right variant="link" toggle-class="text-decoration-none" no-caret :disabled="locked">
                  <template #button-content>
                    <feather-icon size="24" icon="AlignJustifyIcon" />
                  </template>
                  <b-dropdown-item @click="resetDatasource(item.id)">Unlink to Chart</b-dropdown-item>
                  <b-dropdown-item @click="removeItem(item.id)">Delete</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="flex-grow-1">
              <template v-if="item.data_source">
                <charts :ref="'ec' + item.id" :datasourceId="item.data_source" />
              </template>
              <template v-else>
                <div class="w-100 h-100 pt-2 px-2">
                  <b-input placeholder="Enter Datasource ID then Enter" @keyup.enter="linkToChart($event, item.id)"></b-input>
                </div>
              </template>
            </div>
          </div>
        </dash-item>
      </dash-layout>
    </dashboard>
  </div>
</template>

<script>
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import store from "@/store";
import Charts from "./Charts.vue"

export default {
  name: "App",
  components: {
    Dashboard,
    DashLayout,
    DashItem,
    Charts
  },
  data() {
    return {
      loading: false,
      dlayouts: [],
      currentBreakpoint: null,
      locked: true
    };
  },
  mounted() {
    // this.loadList();
    this.loadLayout();
  },
  methods: {
    async loadList() {
      let callback = function(data) {
        data.forEach(e => {
          console.log(e.key + " " + e.fields.summary);
        });
      };

      this.loading = true;

      await this.loadJiraTicket("project = ITSP", ["summary", "status", "assignee", "reporter", "issuetype", "created", "priority"], callback, 0, [], []);

      this.loading = false;
    },
    async loadJiraTicket(jql, fields, callback, startAt, fullList, expand) {
      try {
        const response = await fetcher(apis.jiraSearch, FetchMethod.POST, { jql: jql, startAt: startAt, maxResults: 100, fields: fields, expand: expand });
        const issues = JSON.parse(response);
        this.total = issues.total;
        this.progress = fullList.length;
        fullList = fullList.concat(issues.issues);
        if (issues.maxResults <= issues.issues.length) {
          await this.loadJiraTicket(jql, fields, callback, startAt + issues.issues.length, fullList, expand);
        } else {
          callback(fullList);
        }
      } catch (err) {
        console.error(err);
      }
    },
    itemResized(item) {
      this.layoutChange(false);
    },
    itemMoved(b) {
      this.layoutChange(false);
    },
    breakpointUpdated(bp) {
      this.currentBreakpoint = bp;
    },
    async layoutChange(u) {
      const contactRes = await fetcher(apis.dynamicChart + "/jira-chart-layout-001", FetchMethod.POST, this.dlayouts);
      if (u) {
        this.dlayouts = contactRes;
      }
    },
    async loadLayout() {
      this.loading = true;
      this.dlayouts = await fetcher(apis.dynamicChart + "/jira-chart-layout-001", FetchMethod.GET);
      this.loading = false;
    },
    addItem() {
      if (this.locked) return;
      let l = this.dlayouts.filter(d => d.breakpoint === this.currentBreakpoint);
      l[0].items.push({
        height: 1,
        id: Date.now().toString(),
        width: 1,
        x: 0,
        y: 0
      });
      this.layoutChange();
    },
    removeItem(id) {
      if (this.locked) return;
      let l = this.dlayouts.filter(d => d.breakpoint === this.currentBreakpoint);

      for (var i = 0; i < l[0].items.length; i++) {
        if (l[0].items[i].id === id) {
          l[0].items.splice(i, 1);
          break;
        }
      }
      this.layoutChange();
    },
    resetDatasource(id) {
      let l = this.dlayouts.filter(d => d.breakpoint === this.currentBreakpoint);
      if (l.length !== 1) return;
      let items = l[0].items.filter(i => i.id == id);
      if (items.length !== 1) return;
      delete items[0]["data_source"];
      this.layoutChange(true);
    },
    linkToChart(e, id) {
      if (!e.srcElement._value) return;
      let l = this.dlayouts.filter(d => d.breakpoint === this.currentBreakpoint);
      if (l.length !== 1) return;
      let items = l[0].items.filter(i => i.id == id);
      if (items.length !== 1) return;

      fetcher(apis.dynamicChart + "/" + e.srcElement._value, FetchMethod.GET)
        .then(r => {
          console.log(r);
          items[0].data_source = e.srcElement._value;
          this.layoutChange(true);
        })
        .catch(err => {
          store.commit("app/SHOW_ERROR_DIALOG", {
            isOpen: true,
            title: "Unable to find data source",
            message: "Unable to find data source with id '" + e.srcElement._value + "'"
          });
        });
    }
  }
};
</script>

<style></style>
